<template>
  <div class="page-leaveteacher">
    <div class="tab-box">
      <div
        class="tab-item"
        v-for="(num, index) in tabs"
        :key="index"
        :class="{ active: index === tabNum }"
        @click="tabClick(index)"
      >
        {{ num }}
      </div>
    </div>
    <my v-if="tabNum === 1"  />
    <add v-if="tabNum === 0"  @addClick='addClick' />
    <!-- <audit v-if="tabNum === 1" /> -->
  </div>
</template>

<script>
import { Icon, Image, ImagePreview } from 'vant'
import My from './My/List'
import Add from './My/Add'
// import Audit from './Audit/List'

export default {
  components: {
    My,
    Add
    // Audit,
  },
  data () {
    return {
      tabs: ['我要请假','请假记录'],
      tabNum: 0
    }
  },
  mounted () {
    // this.getUser()
    if (this.$route.query.active) {
      this.tabNum = Number(this.$route.query.active)
    }
  },
  methods: {
    tabClick (index) {
      this.tabNum = index
	  // 切换按钮,路由参数清空
	  this.$route.query.url = ''
    },
	addClick(){
		this.tabNum = 1
	}
  }
}
</script>
<style lang="less" scoped>
@import './index.less';
</style>
