<template>
  <div class="page-folder">
    <div class="my-page">
      <div class="list-box">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="getDataList"
            offset="10"
          >
            <div
              class="my-card"
              v-for="(item, i) in dataList"
              :key="i"
              @click="handleClick(item)"
            >
              <p class="p">请假宝贝：{{ item.ChildrenName }}</p>
              <div class="content-box">
				  <div>
				    请假类型：<span>{{ item.LeaveTypeName }}</span>
				  </div>
                <div>
                  请假开始时间：<span>{{ item.BeginDate }}</span>
                </div>
                <div>
                  请假结束时间：<span>{{ item.EndDate }}</span>
                </div>
                <div>
                  请假人：<span>{{ item.RealName }}</span>
                </div>
                <div v-if="item.LeaveStateName === '正常'" style="text-align: right">
                  <span class="xj-btn" @click.stop="xjOpen(item)">
                    <van-icon name="delete-o" />
                    <span class="xj">销假</span>
                  </span>
                </div>
              </div>
              <!-- 审核状态 -->
              <template v-if="item.LeaveStateName === '正常'">
                <div
                  v-if="item.AuditStateName === '未审核'"
                  class="confirm-box"
                ></div>
                <div
                  v-if="item.AuditStateName === '通过'"
                  class="confirm-box type1"
                ></div>
                <div
                  v-if="item.AuditStateName === '驳回'"
                  class="confirm-box type2"
                ></div>
              </template>
              <template v-else>
                <div class="confirm-box type3"></div>
              </template>
            </div>
          </van-list>
        </van-pull-refresh>
      </div>
    </div>
    <!-- 新增悬浮按钮 -->
    <!-- <div class="add-btn" @click="add">+</div> -->
    <!-- 驳回弹窗 -->
    <van-dialog
      v-model="dialogVisible"
      title="销假"
      :beforeClose="beforeClose"
      showCancelButton
    >
      <van-field
        required
        v-model="CancelDesc"
        name="CancelDesc"
        rows="2"
        label="销假说明"
        type="textarea"
        placeholder="请添加销假说明"
		:rules="[{ required: false, message: '请填写销假说明'}]"
      />
    </van-dialog>
  </div>
</template>

<script>
import { Button, Icon, List, PullRefresh, Dialog, Field } from 'vant'
export default {
  components: {
    [Button.name]: Button,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Icon.name]: Icon,
    [Dialog.Component.name]: Dialog.Component,
    [Field.name]: Field
  },
  data () {
    return {
      userType: window.localStorage.getItem('UserType'),
      loading: false,
      finished: false, // false
      refreshing: false,
      dialogVisible: false,
      id: '',
      CancelDesc: '',
      dataList: [],
      page: 0, // 页码
      limit: 10 // 每页条数
    }
  },
  mounted () {
    // 空白时候自动触发
    // this.getDataList()
  },
  methods: {
    getDataList () {
      this.page++
      this.getList()
    },
    getList () {
      const that = this
      that.$axios
        .get('/api/LeaveForChildren/ListForParent', {
          page: this.page, // 页码
          limit: this.limit // 每页条数
        })
        .then(res => {
          if (res.code === 200) {
            that.refreshing = false
            that.loading = false
            that.dataList = that.dataList.concat(res.data)
            if (that.dataList.length >= res.count) {
              that.finished = true
            }
          } else {
            that.$toast.fail(res.msg || '操作失败')
          }
        })
    },
    // 下拉刷新
    onRefresh () {
      this.page = 0
      // 清空列表数据
      this.finished = false
      // 将 loading 设置为 true，表示处于加载状态
      // this.loading = true
      this.dataList = []
      this.getDataList()
    },
    handleClick (item) {
      if (item.LeaveStateName === '销假') {
        this.$router.push({
          path: 'LeaveBobyMyView',
          query: {
            id: item.ID
          }
        })
      } else {
        if (item.AuditStateName === '未审核') {
          this.$router.push({
            path: 'LeaveBobyMyEdit',
            query: {
              id: item.ID
            }
          })
        } else {
          this.$router.push({
            path: 'LeaveBobyMyView',
            query: {
              id: item.ID
            }
          })
        }
      }
    },
    openOrClose (e) {
      const dom = this.$jq(e.currentTarget).find('i')
      if (dom.hasClass('i-down')) {
        dom.removeClass('i-down')
        dom
          .parent()
          .parent()
          .parent()
          .parent()
          .siblings('.card-bottom')
          .show()
      } else {
        dom.addClass('i-down')
        dom
          .parent()
          .parent()
          .parent()
          .parent()
          .siblings('.card-bottom')
          .hide()
      }
    },
    add () {
      this.$router.push('LeaveBobyMyAdd')
    },
    xjOpen (item) {
      this.id = item.ID
      this.dialogVisible = true
    },
    beforeClose (action, done) {
      let that = this
      if (action === 'confirm') {
        if (!this.CancelDesc) {
          this.$toast.fail('请填写销假原因！')
          done(false)
        } else {
          this.$axios
            .post('/api/LeaveForChildren/Cancel', {
              CancelDesc: this.CancelDesc,
              ID: this.id
            })
            .then(res => {
              if (res.code === 200) {
                this.$toast.success(res.msg || '操作成功')
                this.CancelDesc = ''
                done()
                setTimeout(() => {
                  // that.$router.go(0)
                  window.location.reload()
                }, 1000)
              } else {
                this.$toast.fail(res.msg || '操作失败')
              }
            })
        }
      } else {
        this.CancelDesc = ''
        done()
      }
    }
  }
}
</script>
<style lang="less" scoped>
@import './index.less';
</style>
